<template>
  <div className="container">
    <div className="sider">
      <button className="btn" @click="scrollTo('efficiency')">全部</button>
      <button className="btn" @click="scrollTo('efficiency')">效率</button>
      <button className="btn" @click="scrollTo('marketing')">营销</button>
      <button className="btn" @click="scrollTo('entertainment')">娱乐</button>
    </div>
    <div className="right-content">
      <div v-for="(group, i) in groups" :key="i" :ref="group.ref" class="right-content-sub">
        <!--通过$refs获取到当前box所在的group的ref，再通过$event.target获取到当前box的index，最后通过item.title和item.description获取到对应的数据。-->
        <!--让鼠标移动到div时显示小手，只需要在CSS样式表中为该div设置cursor属性的值为"pointer"即可。
        在Vue中，你可以使用`v-bind`指令来绑定元素的属性。可以在box元素上添加一个`v-bind`指令，将其绑定到一个变量上，然后在Vue实例中定义该变量的值为"pointer"。-->
        <div v-for="(item, j) in items.filter(it => it.ref === group.ref)" :key="j" class="box"
             @click="handleBoxClick($event, group.ref, j, item.title, item.description)"
             v-bind:style="{ cursor: boxCursor }">
          <div class="box-content">
            <img :src="require(`@/views/images/${item.pic}`)">
            <p class="title">{{ item.title }}</p>
            <p class="description">{{ item.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active" active-color="#16a37f" inactive-color="#000">
      <van-tabbar-item icon="chat-o" to="/landing">问答</van-tabbar-item>
      <van-tabbar-item icon="like-o" dot to="/examples_landing">应用</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<!--<script>
export default {
  name: "MyComponent",
  methods: {
    scrollTo(refName) {
      const element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>-->

<script>
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: "MyComponent",
  methods: {
    scrollTo(refName) {
      const element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth" });
    },
    handleBoxClick(event, ref, index, title, description) {
      this.$router.push({
        name: 'application_landing',
        params: {
          ref: ref,
          index: index,
          title: title,
          description: description
        }
      });
      //console.log(ref, index, title, description);
    }
  },
  data() {
    return {
      boxCursor: 'pointer',
      items: [
        {ref: 'efficiency', index: 0, title: '日报', description: '根据您今日的工作内容，帮您生成今日日报', pic: 'icon_efficiency_rb.png'},
        {ref: 'efficiency', index: 1, title: '周报', description: '根据您本周的工作内容，帮您生成本周周报', pic: 'icon_efficiency_zb.png'},
        {ref: 'efficiency', index: 2, title: '短视频脚本', description: '抖音|快手|小红书，短视频创作', pic: 'icon_efficiency_zb.png'},
        {ref: 'efficiency', index: 3, title: '旅游计划', description: '根据你的旅游要求，帮您生成旅游计划', pic: 'icon_efficiency_ly.png'},
        {ref: 'marketing', index: 0, title: '小红书', description: '帮助你生成小红书的Emoji风格文案', pic: 'icon_marketing_xhs.png'},
        {ref: 'marketing', index: 1, title: '大众点评|美团', description: '探店打卡，帮您生成大众点评|美团的风格文案', pic: 'icon_marketing_hotel.png'},
        {ref: 'marketing', index: 2, title: '淘宝|京东', description: '商品评价，帮您生成购物风格文案', pic: 'icon_marketing_goods.png'},
        {ref: 'marketing', index: 3, title: '朋友圈营销文案', description: '一键转换，生成带格式适合朋友圈传播文案', pic: 'icon_marketing_pyqyingxiao.png'},
        {ref: 'entertainment', index: 0, title: '哄女友帮助', description: '女朋友生气怎么办？用我帮您分析', pic: 'icon_entertainment_girl_friend1.png'},
        {ref: 'entertainment', index: 1, title: '单身狗分析', description: '分析如何帮您追女朋友|男朋友', pic: 'icon_entertainment_girl_friend2.png'},
        {ref: 'entertainment', index: 2, title: '节日祝福', description: '过节了，帮你写祝福语', pic: 'icon_entertainment_blessing.png'},
        {ref: 'entertainment', index: 3, title: 'AI解梦', description: '根据您的梦境，分析您的当前运势', pic: 'icon_entertainment_dream.png'},
        {ref: 'entertainment', index: 4, title: '高情商回复', description: '帮助你高情商答复而不伤和气', pic: 'icon_entertainment_gqs.png'},
        {ref: 'entertainment', index: 5, title: '甩锅助手', description: '帮助你分析如何推卸责任', pic: 'icon_entertainment_blameSifting.png'},
        {ref: 'entertainment', index: 6, title: '夸夸助手', description: '教你如何夸人', pic: 'icon_entertainment_brag.png'},
        {ref: 'entertainment', index: 7, title: '吵架助手', description: '教你如何不再脏字吵架', pic: 'icon_entertainment_cj.png'}
      ],
      groups: [
        {ref: 'efficiency', title: '效率工具'},
        {ref: 'marketing', title: '营销工具'},
        {ref: 'entertainment', title: '娱乐工具'}
      ]
    }
  },
  setup() {

    let active = ref(1);
    const route = useRoute();
    const router = useRouter();

    const store = useStore()
    const model = computed(() => store.getters.isModel)

    onMounted(() => {
      if (route.name === "landing") {
        active.value = 0;
      } else if (route.name === 'examples_landing') {
        active.value = 1
      }
    });

    watch(
        () => router.currentRoute.value,
        (newValue, oldValue) => {
          if (newValue.name === "landing") {
            active.value = 0;
          } else if (route.name === 'examples_landing') {
            active.value = 1
          }
        },
        { immediate: true }
    );

    return {
      active,
      model
    };
  },
};
</script>

<style>
.container {
  /*如果设置了`overflow: auto;`但是没有生效，可能是因为父元素的高度没有设置，或者设置了固定高度。可以尝试给父元素设置一个最小高度，或者使用`vh`单位来设置高度。*/
  height: calc(100% - 0px);
  display: flex;
  width: 100%;
  flex-direction: row;
}

.sider {
  overflow: auto;
  display: flex;
  flex-direction: column;
  /*如何让sider永远占屏幕20%，content永远占屏幕80%
  1. 将容器设置为flex布局，设置flex-direction为row，使sider和content并排排列。
  2. 给sider设置一个固定宽度，使用flex: 0 0 25%来实现，表示不可伸缩，初始宽度为25%。
  3. 给content设置flex: 1，表示可伸缩，占据剩余的空间。
  4. 使用box-sizing: border-box来保证元素的宽度包括padding和border。
  如果sider和content都被挤到左边了，是因为没有设置容器的宽度导致的。可以给容器设置一个宽度，比如100%。*/
  flex: 0 0 20%;
  box-sizing: border-box;
  background-color: #f5f5f5;
}

.btn {
  height: 50px;
  font-size: 15px;
  border: none;
  background-color: #f5f5f5;
}

/*这段代码表示当鼠标悬停在按钮上或者按钮被点击时，按钮的背景颜色变为白色，文字颜色变为黑色。*/
.btn:hover,
.btn:active {
  background-color: white;
  color: black;
}

/*这段代码表示当按钮被聚焦时，背景颜色变为白色，文字颜色变为黑色。
聚焦（Focus）是指用户在页面上点击或使用键盘操作，使得某个元素（如按钮、输入框等）成为当前活动元素*/
.btn:focus {
  background-color: white;
  color: black;
}

.right-content {
  flex: 1;
  box-sizing: border-box;
  overflow: auto;
  background-color: #fff;
  padding-bottom: 80px;
}

.right-content-sub {
  display: flex;
  /*`flex-wrap: wrap;` 是 CSS 中 flex 布局的一个属性，它的作用是控制 flex 容器中的 flex 元素是否换行。
  当 `flex-wrap` 属性的值为 `nowrap` 时，flex 元素不会换行，而是尽可能地在一行中排列。
  当 `flex-wrap` 属性的值为 `wrap` 时，flex 元素会在需要换行时自动换行，以保证它们能够适应容器的宽度。*/
  flex-wrap: wrap;
  margin-right: 20px;
  /*之前是10px但是发现点击底部tab会将这一设定传染给跳转的页面*/
  margin-top: 0px;
}

.box {
  padding-top:10px;
  flex-basis: calc(50% - 22px);
  aspect-ratio: 1/1;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.box-content {
  flex-basis: 100%;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;/*居中*/
  justify-content: center;
}

.title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: -5px;
  margin-top: 8px;
}

.description {
  font-size: 14px;
  color: #999;
  /*- display: -webkit-box; 将元素设置为弹性盒子布局。
- -webkit-line-clamp: 2; 设置文本显示的行数为2行。
- -webkit-box-orient: vertical; 设置弹性盒子的排列方向为垂直方向。
- overflow: hidden; 隐藏超出元素框的内容。
- text-overflow: ellipsis; 当文本溢出时，用省略号代替。*/
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.box img {
  display: block;
  max-width: 20%;
  max-height: 20%;
  /*margin-top: 10px;*/
}

@media only screen and (min-width: 700px) {
  .container{
    width: 640px;
    margin: 0 auto;
  }
  .van-tabbar{
    width: 640px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

</style>